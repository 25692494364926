import { createContext, useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'

export const ToastContext = createContext<HTMLDivElement | null>(null)

const ToastContainer = styled.div(
  () => css`
    z-index: 2;
  `,
)
export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const toastContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setRef(toastContainerRef.current)
  }, [toastContainerRef])

  return (
    <>
      <ToastContext.Provider value={ref}>{children}</ToastContext.Provider>
      <ToastContainer ref={toastContainerRef} />
    </>
  )
}

export default ToastProvider
