const waitTimeout = (callback: () => void, time: number) => {
  let start: number

  if (!window.requestAnimationFrame) {
    setTimeout(callback, time)
    return
  }

  const loop = () =>
    window.requestAnimationFrame((ts) => {
      if (!start) start = ts
      const elapsed = ts - start
      if (elapsed > time) {
        callback()
        return
      }

      loop()
    })

  loop()
}

export default waitTimeout
