import { hexToRgba, Icon, Text } from '@lemonenergy/lemonpie'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext, useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'

import { toastAnimations } from './toastAnimations'
import { ToastContext } from './ToastProvider'

import waitTimeout from '~/utils/waitTimeout'

type ToastPosition = 'top' | 'bottom'

export interface ToastProps {
  isOpen?: boolean
  onClose: () => void
  timeBeforeClose?: number
  position?: ToastPosition
  children: React.ReactNode
}

const StyledToast = motion(
  styled.div<{ $position: ToastPosition }>(
    ({ $position, theme: { colors, radii, spacing } }) => {
      const positionStyles = {
        top: css`
          top: 10%;
        `,
        bottom: css`
          bottom: 0;
        `,
      }

      return css`
        position: fixed;
        ${positionStyles[$position]};
        right: 0;

        display: flex;
        gap: ${spacing.xs};
        background-color: ${colors.neutral.darkest};
        border-radius: ${radii.xs};
        color: ${colors.primary.lightest};
        padding: ${spacing.sm};
        margin: 0 ${spacing.md} ${spacing.md};
        box-shadow: 0 8px 32px 0 ${hexToRgba(colors.neutral.main, 0.2)};

        ${Icon} {
          flex: 0 0 auto;
        }

        ${Text} {
          flex: 1 1 auto;
        }
      `
    },
  ),
)

const Toast: React.FC<ToastProps> = ({
  isOpen = false,
  onClose,
  timeBeforeClose = 5000,
  position = 'bottom',
  children,
  ...props
}) => {
  const toastContainerRef = useContext(ToastContext)

  useEffect(() => {
    if (!isOpen || !onClose) return

    waitTimeout(() => isOpen && onClose(), timeBeforeClose)
  }, [isOpen, onClose, timeBeforeClose])

  if (!toastContainerRef) return null

  const animation = toastAnimations[position]

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <StyledToast $position={position} {...animation} {...props}>
          {children}
        </StyledToast>
      )}
    </AnimatePresence>,
    toastContainerRef,
  )
}

export default Toast
