export const toastAnimations = {
  bottom: {
    initial: {
      y: '150%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '150%',
    },
    transition: { durantion: 0.6 },
  },
  top: {
    initial: {
      y: '-150%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '-150%',
    },
    transition: { durantion: 0.6 },
  },
}
